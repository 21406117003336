import Layout from '@/modules/layout/components/layout';
import Permissions from '@/security/permissions';
import { storeAsync } from '../../app-module';

const DocumentSeriesAllocationList = () => import('@/modules/document-series-allocation/pages/DocumentSeriesAllocationList.vue');

const getDocumentSeriesAllocationModule = () => import('@/modules/document-series-allocation/document-series-allocation-store');
const importDocumentSeriesAllocationStore = () =>
    getDocumentSeriesAllocationModule().then((storeModule) => {
        if (!storeAsync().hasModule('documentSeriesAllocation')) storeAsync().registerModule('documentSeriesAllocation', storeModule.default);
        else return;
    });


const getApplicationsModule = () => import("@/modules/applications/applications-store");
const importApplicationsStore = () =>
    getApplicationsModule().then((storeModule) => {
        if (!storeAsync().hasModule("applications"))
            storeAsync().registerModule("applications", storeModule.default);
        else return;
    });

export default [
    {
        path: '',
        component: Layout,
        meta: { auth: true },
        children: [
            {
                name: 'document-series-allocation',
                path: '/document-series-allocation',
                component: DocumentSeriesAllocationList,
                meta: {
                    auth: true,
                    permission: Permissions.values.documentSeriesAllocationRead,
                },
                beforeEnter: (to, from, next) => {
                    const store = storeAsync();
                    store.commit('shared/setModuleName','documentSeriesAllocation');

                    importDocumentSeriesAllocationStore().then(() => {
                        next();
                    });

                    importApplicationsStore().then(() => {
                        next();
                    });
                },
            },
        ],
    },
];
